import React from "react";
import MenuBar from "./common/MenuBar";
import { HashLink } from "react-router-hash-link";
import box from "../assets/box.png";

const Prize = () => {
  return (
    <div className=" relative w-full max-h-[100vh] bg-blue-600">
      <MenuBar />
      <p className="px-6 text-2xl font-bold">Prize</p>
      <div className="w-full flex flex-col justify-center items-center">
        <p className="text-2xl font-semibold text-white">
          Recharge and get lucky draw opportunities
        </p>
        <HashLink>
          <p className="text-xl mt-4 bg-white p-2 w-20 text-center rounded-xl font-semibold">
            Rules
          </p>
        </HashLink>
      </div>
      <div className="grid place-items-center mt-10">
        <div className="grid grid-cols-2">
          <img
            className="h-32 cursor-pointer hover:scale-105 duration-200"
            src={box}
            alt=""
          />
          <img
            className="h-32 cursor-pointer hover:scale-105 duration-200"
            src={box}
            alt=""
          />
          <img
            className="h-32 cursor-pointer hover:scale-105 duration-200"
            src={box}
            alt=""
          />
          <img
            className="h-32 cursor-pointer hover:scale-105 duration-200"
            src={box}
            alt=""
          />
        </div>
      </div>
      <div className="bg-white h-[250px] rounded-t-2xl">
        <p className="text-xl text-center mt-3 font-semibold">
          List Of Winners
        </p>
        <div className="mt-2 h-full overflow-y-hidden ">
          <div className="animate">
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
          </div>
          <div className="animate2">
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
            <span className="flex justify-between px-12">
              <p>943****123</p>
              <p>₹ 605.00</p>
              <p>2024-03-25 02:09:25</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prize;
